const color = {
    brand_01: "#FFAA9B",
    brand_02: "#0157FF",
    ways: {
        CW1: {
            primary: "#FFCB97",
            secondary: "#41A569",
        },
        CW2: {
            primary: "#FBD8EC",
            secondary: "#897A00",
        },
        CW3: {
            primary: "#FFE825",
            secondary: "#8C68F5",
        },
        CW4: {
            primary: "#FFBBFF",
            secondary: "#FF8325",
        },
        CW5: {
            primary: "#FFCBFF",
            secondary: "#FF2900",
        },
        CW6: {
            primary: "#FFF0D7",
            secondary: "#00ABFF",
        },
    },
    base: {
        c0: "#FFFFFF",
        c1: "#F8F9FA",
        c2: "#E9ECEF",
        c3: "#DEE2E6",
        c4: "#CED4DA",
        c5: "#ADB5BD",
        c6: "#6C757D",
        c7: "#495057",
        c8: "#343A40",
        c9: "#212529",
        c10: "#000000",
    },
    success: "#19E05D",
    warning: "#D6D6D6",
    error: "#E03919",
    info: "#D6D6D6",
};
export default color;
