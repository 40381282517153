export const fontSize = {
    head: "26px",
    subhead: "20px",
    title: "18px",
    body: "16px",
    body2: "14px",
    footnote: "12px",
};
export const fontFamily = {
    core: {
        w400: "RoobertPRO-Regular",
        w400Italic: "RoobertPRO-RegularItalic",
        w600: "RoobertPRO-SemiBold",
        w600Italic: "RoobertPRO-SemiBoldItalic",
    },
    monospace: {
        w400: "RobotoMono400",
        w500: "RobotoMono500",
        w600: "RobotoMono600",
        w700: "RobotoMono700",
    },
};
