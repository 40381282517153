const light = {
    base: {
        cs01: ['#1E293B', '#475569'],
        cs02: ['#0F172A', '#334155'],
        cs03: ['#CBD5E1', '#E2E8F0'],
        cs04: ['#94A3B8', '#CBD5E1'],
    },
    primary: {
        cs01: ['#4F46E5', '#A5B4FC'],
        cs02: ['#3730A3', '#6366F1'],
    },
    tertiary: {
        cs01: ['#6D28D9', '#A78BFA'],
        cs02: ['#5B21B6', '#A78BFA'],
    },
};
export default light;
